<template>
    <CompTable ref="comp_table" title="栏目更新提醒设置" :columns="columns" :table-api="tableApi"></CompTable>
</template>

<script>
import CompTable from "@/views/jointly/components/comp-table.vue"
import Request from "../../jointly/utils/request"

export default {
    components: {
        CompTable,
    },

    data() {
        const orgName = this.$core.getUrlParam("orgName")
        const orgCode = this.$core.getUrlParam("orgCode")

        return {
            tableApi: `/gateway/api/symanage/pc/columnAlarm/selectOrgHasColumnCode?orgCode=${orgCode}`,
            columns: [
                {
                    title: "机构名称",
                    render: () => orgName,
                },
                {
                    title: "栏目名称",
                    key: "columnName",
                },
                {
                    title: "栏目编码",
                    key: "columnCode",
                },
                {
                    title: "提醒周期设置",
                    render: (h, params) => {
                        var types = ["不提醒", "每天", "每周", "每月", "每季度", "每年"]

                        return h(
                            "Select",
                            {
                                props: {
                                    value: params.row.alarmType,
                                },
                                style: {
                                    maxWidth: "200px",
                                },
                                on: {
                                    "on-change": v => {
                                        Request.post(
                                            "/gateway/api/symanage/pc/columnAlarm/saveData",
                                            {
                                                alarmId: params.row.alarmId,
                                                orgCode: params.row.orgCode,
                                                columnCode: params.row.columnCode,
                                                columnName: params.row.columnName,
                                                alarmType: v,
                                            },
                                            {
                                                json: true,
                                            }
                                        ).then(() => {
                                            this.$Message.success("设置成功")
                                        })
                                    },
                                },
                            },
                            types.map((t, i) =>
                                h(
                                    "Option",
                                    {
                                        props: {
                                            value: i + "",
                                        },
                                    },
                                    t
                                )
                            )
                        )
                    },
                },
            ],
        }
    },
}
</script>

<style></style>
